var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-layout"
  }, [_c('notifications'), _vm._m(0), _c('router-view')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-header"
  }, [_c('a', {
    staticClass: "logo-link",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/logo.svg",
      "alt": "Altera"
    }
  })]), _c('a', {
    staticClass: "btn-join",
    attrs: {
      "href": "https://www.altera-global.net",
      "target": "_blank"
    }
  }, [_vm._v("Join us")])]);

}]

export { render, staticRenderFns }